const getResourceUrl = (path) => {
    if(!path)
        return path;

    if (path.startsWith('http://') || path.startsWith('https://') || path.startsWith('/assets/')) {
        return path;
    }

    let baseUrl = process.env.REACT_APP_BLOB_STORAGE;

    if (baseUrl.endsWith('/')) {
        baseUrl = baseUrl.slice(0, -1);
    }
    if (path.startsWith('/')) {
        path = path.slice(1);
    }

    return `${baseUrl}/${path}`;
}

export { getResourceUrl };
