import React from 'react';
import {InfluencerData} from "../abstract/InfluencerData";
import styled from "styled-components";
import {getResourceUrl} from "../utils/urlUtils";

interface BackgroundWrapperProps {
    influencer: InfluencerData | null;
}

const StyledBackgroundWrapper = styled.div<{ $backgroundColor: string }>`
  background-color: ${props => props.$backgroundColor};
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  z-index: -3;
`;

const StyledBackgroundImage = styled.div<{ $imageUrl: string | null }>`
  background-image: ${props => props.$imageUrl ? `url(${props.$imageUrl})` : 'none'};
  background-size: cover;
  background-position: top;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -2;
`;

const StyledVideo = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
`;

const BackgroundComponent: React.FC<BackgroundWrapperProps> = ({ influencer }) => {
    return (
        <StyledBackgroundWrapper $backgroundColor={influencer?.backgroundColor || '#fff'}>
            {influencer?.backgroundImage && (
                <StyledBackgroundImage $imageUrl={getResourceUrl(influencer.backgroundImage)} />
            )}
            {influencer?.backgroundVideo && (
                <StyledVideo autoPlay loop muted>
                    <source src={getResourceUrl(influencer.backgroundVideo)} type="video/webm" />
                </StyledVideo>
            )}
        </StyledBackgroundWrapper>

    );
};


export default BackgroundComponent;
