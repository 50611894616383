import React from 'react';
import styled from 'styled-components';
import {InfluencerData} from "../abstract/InfluencerData";
import ShareButton from "./ShareButton";
import {getResourceUrl} from "../utils/urlUtils";

const ProfileContainer = styled.div`
  text-align: center;
  margin: 20px;
`;

const Avatar = styled.img`
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: 3px white solid;
  margin-bottom: 10px;
  box-shadow: rgba(0, 0, 0, 0.05) 0 0 5px 0;

`;

interface Props {
    influencer: InfluencerData;
}

const InfluencerProfile: React.FC<Props> = ({ influencer }) => {
    return (
        <ProfileContainer>
            <Avatar src={getResourceUrl(influencer.profileImage)} alt={influencer.name} />
            <h2 className="text-white-shadow">{influencer.name}</h2>
            <p className="text-white-shadow">{influencer.description}</p>
            {influencer.socialMediaLinks.map((link, index) => (
                <ShareButton key={link.id + index} title={link.title} link={link.link} restricted={link.restricted} icon={link.icon} />
            ))}
        </ProfileContainer>
    );
};

export default InfluencerProfile;
