import React, {useCallback, useEffect, useState} from 'react';
import {InfluencerData} from "./abstract/InfluencerData";
import InfluencerProfile from "./components/InfluencerProfile";
import {getResourceUrl} from "./utils/urlUtils";
import BackgroundComponent from "./components/BackgroundComponent";
import {Helmet, HelmetProvider} from 'react-helmet-async';
import Footer from "./components/Footer";
import ShareApiWebButton from "./components/ShareApiWebButton";

const DEFAULT_DATA = "data/default.json";

const App: React.FC = () => {
    const [influencer, setInfluencer] = useState<InfluencerData>();

    const metaTags = [
        {property: 'og:title', content: "Share Links"},
        {property: 'og:description', content: "One link to share everything"},
        {property: 'og:type', content: 'profile'}
    ];

    const loadData = useCallback((url: string) => {
        fetch(url)
            .then((response) => response.json())
            .then((data: InfluencerData) => {
                if (!data) throw new Error("There is no data for given URL");
                setInfluencer(data);
            })
            .catch((error) => {
                console.error("Error while loading data:", error);
                if (url !== getResourceUrl(DEFAULT_DATA)) {
                    loadData(getResourceUrl(DEFAULT_DATA));
                }
            });
    }, []);

    useEffect(() => {
        const hostname = window.location.hostname.replace(/^www\./, "");
        loadData(getResourceUrl(`data/${hostname}.json`));
    }, [loadData]);

    useEffect(() => {
        if (influencer) {
            updatePageTitleAndMetaTags(influencer);
        }
    }, [influencer]);

    const updatePageTitleAndMetaTags = (influencer: InfluencerData) => {
        document.title = influencer.name;

        const metaTags = [
            {property: 'og:title', content: influencer.name},
            {property: 'og:description', content: influencer.description},
            {property: 'og:type', content: 'profile'},
            {property: 'og:url', content: window.location.href},
            {property: 'og:image', content: getResourceUrl(influencer.profileImage)}
        ];

        return (
                <Helmet>
                    <title>{influencer.name}</title>
                    <meta name="description" content={influencer.description}/>
                    {metaTags.map(tag => (
                        <meta key={tag.property} property={tag.property} content={tag.content}/>
                    ))}
                </Helmet>
        );
    };


    return (
        <HelmetProvider>
        <div className="main">
            {!influencer &&
                <Helmet>
                    <title>Share Links</title>
                    <meta name="description" content="One link to share everything"/>
                    {metaTags.map(tag => (
                        <meta key={tag.property} property={tag.property} content={tag.content}/>
                    ))}
                </Helmet>
            }
            {influencer &&
                <>
                    {updatePageTitleAndMetaTags(influencer)}
                    <header>                    <ShareApiWebButton
                        title={influencer.name}
                        url={window.location.href}
                        text={influencer.description}
                    /></header>
                    <div className="container">
                        <section>
                            <InfluencerProfile influencer={influencer}/>
                        </section>
                    </div>
                    <BackgroundComponent influencer={influencer}/>
                </>
            }
{/*
            <Footer/>
*/}
        </div>
        </HelmetProvider>
    );
};

export default App;
