import React, {useState} from 'react';
import styled, {css} from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    faTwitch,
    faTiktok,
    faYoutube,
    faInstagram,
    IconDefinition, faXTwitter, faLinkedin, faFacebook, faSpotify
} from '@fortawesome/free-brands-svg-icons';
import {faBan, faGlobe, faLock, faStore} from "@fortawesome/free-solid-svg-icons";
import CustomIcon from "../utils/CustomIcon";
import {getIconColor} from "../utils/IconColors";
import AgeVerificationDialog from "./AgeVerificationDialog";

interface ButtonProps {
    $customStyles?: ReturnType<typeof css>;
}

const Button = styled.a<ButtonProps>`
  padding: 10px;
  margin: 10px 0;
  gap: 5px;
  text-align: center;
  text-decoration: none !important;
  border-radius: 1.25rem;

  .btn-icon {
    svg {
      ${(props) => props.$customStyles}
    }
  }
`;

const getIcon = (name: string): IconDefinition | null => {
    switch (name.toLowerCase()) {
        case 'twitch':
            return faTwitch;
        case 'tiktok':
            return faTiktok;
        case 'facebook':
            return faFacebook;
        case 'twitter':
            return faXTwitter;
        case 'x':
            return faXTwitter;
        case 'youtube':
            return faYoutube;
        case 'instagram':
            return faInstagram;
        case 'linkedin':
            return faLinkedin;
        case 'spotify':
            return faSpotify;
        case 'store':
            return faStore;
        case 'website':
            return faGlobe;
        case 'ban':
            return faBan;
        case 'lock':
            return faLock;
        default:
            return null;
    }
};

interface Props {
    title: string;
    link?: string;
    icon?: string;
    restricted?: boolean;
    customStyles?: string;
}

const ShareButton: React.FC<Props> = ({title, link, icon, restricted}) => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const handleButtonClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
        if (!link) {
            return;
        }

        if (restricted) {
            e.preventDefault();
            setIsDialogOpen(true);
            return;
        }

        window.open(link, '_blank', 'noopener,noreferrer');

    }

    const handleDialogConfirm = () => {
        setIsDialogOpen(false);
        window.open(link, '_blank', 'noopener,noreferrer');
    };

    const selectedIcon = icon && getIcon(icon);
    const customStyles = getIconColor(icon);

    return (
        <>
            {isDialogOpen &&
                <AgeVerificationDialog
                    onClose={() => setIsDialogOpen(false)}
                    onConfirm={handleDialogConfirm}
                />
            }
            <Button className={`share-button ${link ? '' : 'disabled'}`} onMouseDown={handleButtonClick}
                    $customStyles={customStyles} >
                <div className="btn-icon">
                    {selectedIcon ? <FontAwesomeIcon icon={selectedIcon} color="black"/> : icon &&
                        <CustomIcon name={icon}/>}
                </div>

                <span className="btn-text">
                {title}
            </span>
            </Button>
        </>
    );
};

export default ShareButton;
